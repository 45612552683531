'use client'

import { featureFlagKeys } from 'feature-flags/constants/featureFlags'
import { useFeatureFlag } from 'feature-flags/hooks/useFeatureFlag/useFeatureFlag'
import { IconNextM } from 'icons/components/IconNextM'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import type { PageFeatureFlag } from 'links/types'
import { PURCHASE_DETAIL_PATHNAME } from 'my-purchases/constants/paths'
import { useMemo } from 'react'
import { cx } from 'utils/cx'
import { ClientLink } from 'utils/link/client'

import { sendAnalytics } from '../../analyticsEvents/analyticsEvents'
import type { PurchaseDetail } from '../../types/purchase/purchase'
import { AnalyticsActionType } from '../../utils/constants'

import styles from './OrderStatusLink.module.scss'
import text from 'fukku/styles/texts.module.scss'

interface OrderStatusLinkProps {
	onCloseBanner: () => void
	purchaseDetails: PurchaseDetail
}

export const OrderStatusLink = ({
	onCloseBanner,
	purchaseDetails,
}: OrderStatusLinkProps) => {
	const { t } = useLabels()
	const { enabled, variables } = useFeatureFlag<PageFeatureFlag>(
		featureFlagKeys.MyPurchasesPage
	)

	const { purchaseId } = purchaseDetails

	function handleClickDetails() {
		onCloseBanner()

		sendAnalytics({
			purchaseDetails,
			action: AnalyticsActionType.CLICK,
		})
	}

	const myPurchasesLink = useMemo(
		() =>
			enabled
				? `${PURCHASE_DETAIL_PATHNAME}?type=online&purchaseId=${purchaseId}`
				: `/mypurchases/online/purchase/${purchaseId}`,
		[purchaseId, enabled]
	)

	return (
		<ClientLink
			className={cx(styles.link, text.bodyM)}
			href={myPurchasesLink}
			withLegacyLocale={variables.isLegacy}
			onClick={handleClickDetails}
			linkProps={{
				'aria-label': t(
					'accessibility.commsBanner.myPurchases.viewOrder.button'
				),
				'data-testid': 'orderStatusBanner.goToMyPurchases',
			}}
		>
			{t('myPurchases.banner.viewDetails.button')}
			<IconNextM width={18} height={18} className={styles.icon} />
		</ClientLink>
	)
}
